<template>
  <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
  >
    <div @click="router.push('/')" class="logo">
      <img :src="require('@/assets/logo.jpg')"/>
    </div>

    <!--  自动填满剩余空间  -->
    <div class="flex-grow" />

    <el-col class="input-search" :span="6">
      <el-input v-model="keyword" @change="onKeywordChange" placeholder="搜索文章/问答">
        <template #append>
          <el-button type="primary" @click="router.push('/search?keyword='+keyword)"  :icon="Search" />
        </template>
      </el-input>
    </el-col>

    <el-menu-item index="/">首页</el-menu-item>
    <el-menu-item index="/article/index">文章区</el-menu-item>
    <el-menu-item index="/question/index">问答区</el-menu-item>

    <el-button style="margin: auto 10px;" v-if="!isUserLogin" type="primary" @click="goLoginPage">登录</el-button>

    <div style="width: 80px;display: inline;margin: auto 20px;" v-if="isUserLogin" >
      <el-dropdown>
        <span class="el-dropdown-link">
           <el-avatar shape="square" :src="userInfo.avatar"/>
            <el-icon style="margin: 0px 4px;"><ArrowDown /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="router.push('/user/index')">个人中心</el-dropdown-item>
            <el-dropdown-item  @click="userLogout">注销登录</el-dropdown-item>
            <el-dropdown-item @click="router.push('/admin/home')">去后台</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script setup>
import { ref, watch} from 'vue'
import {useRouter} from "vue-router";
import UserInfoUtil from "@/utils/userInfoUtil";
import {ElMessage} from "element-plus";
import REQUEST from "@/utils/request";
import UserAPI from "@/apis/UserAPI";
import {ArrowDown, Search} from "@element-plus/icons-vue";

const router = useRouter()
const activeIndex = ref('1')

const isUserLogin = ref(false);
const userInfo = ref({})

const keyword = ref("")

const handleSelect = (key, keyPath) => {
  console.log(key, keyPath)

  if(key.startsWith("/")){
    router.push(key)
  }

}
const goLoginPage = ()=>{
  router.push("/user/login")
}

const userLogout = ()=>{
  UserInfoUtil.clearUserInfo()
  REQUEST.post(UserAPI.userLogout.url).then(res=>{
    if(res.code===200){
      ElMessage.success("注销登录成功");
      isUserLogin.value =  UserInfoUtil.isUserLogin()
      UserInfoUtil.clearUserInfo();
    }else {
      ElMessage.error(res.message);
    }
  }).catch(error=>{
    console.log(error)
  })
}

watch(() => router.currentRoute.value.path,() => {
  isUserLogin.value =  UserInfoUtil.isUserLogin()
  userInfo.value = UserInfoUtil.getUserInfo()
},{immediate: true,deep: true})


// 子组件传值给父组件
// eslint-disable-next-line no-undef
const emits = defineEmits(["change"])
const onKeywordChange = ()=>{
  emits("change", keyword.value)
}
</script>

<style scoped>
.el-menu{
  width: 100vw;
}

.logo{
  display: flex;
  justify-items: center;
}

.logo img{
  margin: auto 0px;
  width: 150px;
}

.flex-grow {
  flex-grow: 1;
}

.input-search{
  margin: auto 10px;
}

.el-dropdown-link{
  display: flex;
  align-items: center;
}

</style>