const ArticleAPI = {
    // 前台分页查看文章
    getAllArticleByPage:{
        url:"/article/getAllArticleByPage/{curPage}/{pageSize}"
    },
    // 前台查看文章详情
    getArticleDetails:{
        url:"/article/getArticleDetails/{aid}"
    },
    // 前台查看最新文章
    getNewestArticleList:{
        url:"/article/getNewestArticleList/{num}"
    },
    // 前台查看最热文章
    getHotestArticleList:{
        url:"/article/getHotestArticleList/{num}"
    },
    // 前台更新文章浏览量
    addArticleViews:{
        url:"/article/addArticleViews/{aid}"
    },
    // 前台搜索文章
    selecByKeyword:{
        url:"/article/selecByKeyword/{curPage}/{pageSize}"
    },
    // 后台发布文章
    adminAddArticle:{
        url:"/admin/article/addArticle"
    },
    // 后台分页查看文章
    adminGetAllArticleByPage:{
        url:"/admin/article/getAllArticleByPage/{curPage}/{pageSize}"
    },
    // 后台查看文章详情
    adminGetArticleDetails:{
        url:"/admin/article/getArticleDetails/{aid}"
    },
    // 后台更新文章数据
    adminUpdateArticle:{
        url:"/admin/article/updateArticle"
    },
    // 后台删除文章
    adminDeleteArticle:{
        url:"/admin/article/deleteArticle/{aid}"
    },
}

export default ArticleAPI;