<template>
  <IndexHeader class="index-head-pc"></IndexHeader>
  <IndexMobileHeader class="index-head-mobile"></IndexMobileHeader>
  <div class="home-card">
    <div class="home-index-nav">
      <div class="home-index-nav-item" @click="activeNameChange('newArticle')"
           :class="activeName==='newArticle'?'home-index-nav-item-active':''">最新文章
      </div>
      <div class="home-index-nav-item" @click="activeNameChange('hotArticle')"
           :class="activeName==='hotArticle'?'home-index-nav-item-active':''">热门文章
      </div>
      <div class="home-index-nav-item" @click="activeNameChange('newQuestion')"
           :class="activeName==='newQuestion'?'home-index-nav-item-active':''">最新问答
      </div>
      <div class="home-index-nav-item" @click="activeNameChange('hotQuestion')"
           :class="activeName==='hotQuestion'?'home-index-nav-item-active':''">热门问答
      </div>
    </div>

    <div v-if="activeName==='newArticle'" class="home-index-content">
      <div class="article-card-container">
        <div v-for="articleItem of newArticleList">
          <ArticleContentCard :article='articleItem'></ArticleContentCard>
        </div>
      </div>
    </div>
    <div v-if="activeName==='hotArticle'" class="home-index-content">
      <div class="article-card-container">
        <div v-for="articleItem of hotArticleList">
          <ArticleContentCard :article='articleItem'></ArticleContentCard>
        </div>
      </div>
    </div>
    <div v-if="activeName==='newQuestion'" class="home-index-content">
      <div class="question-card-container">
        <div v-for="questionItem of newQuestionList">
          <QuestionContentCard :question='questionItem'></QuestionContentCard>
        </div>
      </div>
    </div>
    <div v-if="activeName==='hotQuestion'" class="home-index-content">
      <div class="question-card-container">
        <div v-for="questionItem of hotQuestionList">
          <QuestionContentCard :question='questionItem'></QuestionContentCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue"
import IndexHeader from "@/components/IndexHeader.vue";
import IndexMobileHeader from "@/components/IndexMobileHeader.vue";
import REQUEST from "@/utils/request";
import ArticleAPI from "@/apis/ArticleAPI";
import {ElMessage} from "element-plus";
import ArticleContentCard from "@/components/ArticleContentCard.vue";
import QuestionAPI from "@/apis/QuestionAPI";
import QuestionContentCard from "@/components/QuestionContentCard.vue";

onMounted(() => {
  loadContentData()
})

const activeName = ref('newArticle')
const newArticleList = ref([])
const hotArticleList = ref([])
const newQuestionList = ref([])
const hotQuestionList = ref([])
const loadContentNum = 10;

const activeNameChange = (newActiveName) => {
  activeName.value = newActiveName;
  loadContentData()
}

const loadContentData = () => {
  if (activeName.value === "newArticle") {
    // 加载最新文章
    REQUEST.get(ArticleAPI.getNewestArticleList.url.replace("{num}", loadContentNum)).then(res => {
      if (res.code === 200) {
        newArticleList.value = res.data
      } else {
        ElMessage.error(res.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  if (activeName.value === "hotArticle") {
    // 加载最热文章
    REQUEST.get(ArticleAPI.getHotestArticleList.url.replace("{num}", loadContentNum)).then(res => {
      if (res.code === 200) {
        hotArticleList.value = res.data
      } else {
        ElMessage.error(res.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  if (activeName.value === "newQuestion") {
    // 加载最新问答
    REQUEST.get(QuestionAPI.getNewestQuestionList.url.replace("{num}", loadContentNum)).then(res => {
      if (res.code === 200) {
        newQuestionList.value = res.data
      } else {
        ElMessage.error(res.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  if (activeName.value === "hotQuestion") {
    // 加载最热问答
    REQUEST.get(QuestionAPI.getHotestQuestionList.url.replace("{num}", loadContentNum)).then(res => {
      if (res.code === 200) {
        hotQuestionList.value = res.data
        console.log(hotQuestionList.value)
      } else {
        ElMessage.error(res.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }
}


</script>

<style scoped>
@media screen and (min-width: 900px) {
  .index-head-pc {
    display: flex;
  }

  .index-head-mobile {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .index-head-pc {
    display: none;
  }

  .index-head-mobile {
    display: flex;
  }
}

.home-card {
  background-color: #fff;
  margin: 2px;
  border-radius: 6px;
  padding: 10px;
  min-height: 90vh;
}

.home-index-nav {
  display: flex;
  justify-items: center;
  justify-content: left;
}

@media screen and (min-width: 900px) {
  .home-index-nav-item {
    width: 10%;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
  }

  .home-card {
    width: 70%;
    margin: 0px auto;
  }
}

@media screen and (max-width: 900px) {
  .home-index-nav-item {
    width: 25%;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
  }
}

.home-index-nav-item-active {
  border-bottom: 3px solid #409eff;
  color: #409eff;
}

.home-index-content {

}


</style>
