const QuestionAPI = {
    // 前台分页查看文章
    getAllQuestionByPage:{
        url:"/question/getAllQuestionByPage/{curPage}/{pageSize}"
    },
    // 前台查看文章详情
    getQuestionDetails:{
        url:"/question/getQuestionDetails/{qid}"
    },
    // 前台查看最新问答
    getNewestQuestionList:{
        url:"/question/getNewestQuestionList/{num}"
    },
    // 前台查看最热问答
    getHotestQuestionList:{
        url:"/question/getHotestQuestionList/{num}"
    },
    // 前台更新问答浏览量
    addQuestionViews:{
        url:"/question/addQuestionViews/{qid}"
    },
    // 前台搜索问答
    selecByKeyword:{
        url:"/question/selecByKeyword/{curPage}/{pageSize}"
    },
    // 后台发布问答
    adminAddQuestion:{
        url:"/admin/question/addQuestion"
    },
    // 后台分页查看问答
    adminGetAllQuestionByPage:{
        url:"/admin/question/getAllQuestionByPage/{curPage}/{pageSize}"
    },
    // 后台查看文章详情
    adminGetQuestionDetails:{
        url:"/admin/question/getQuestionDetails/{qid}"
    },
    // 后台更新文章数据
    adminUpdateQuestion:{
        url:"/admin/question/updateQuestion"
    },
    // 后台更新文章数据
    adminDeleteQuestion:{
        url:"/admin/question/deleteQuestion/{qid}"
    },
}

export default QuestionAPI;