import REQUEST from "@/utils/request";
import UserAPI from "@/apis/UserAPI";
import {ElMessage} from "element-plus";

const userInfoUtil = {

     setUserInfo : (userInfo)=>{
        window.localStorage.setItem("userInfo",userInfo)
    },

    clearUserInfo : ()=>{
        window.localStorage.setItem("userInfo",null)
    },

    getUserInfo : ()=>{
        let userInfo = window.localStorage.getItem("userInfo")
        if(userInfo){
            userInfo = JSON.parse(userInfo)
            return userInfo;
        }else{
            return null;
        }

        // REQUEST.get(UserAPI.userInfo).then(res=>{
        //     if(res.code===200){
        //         userInfo = res.data
        //         return userInfo;
        //     }else {
        //         ElMessage.error(res.message)
        //         return null;
        //     }
        // }).catch(error=>{
        //     console.log(error)
        // })
    },

    isUserLogin : ()=>{
        let userInfo = window.localStorage.getItem("userInfo")
        if(userInfo==="null" || userInfo === null || userInfo === undefined){
            return false;
        }
        return true;
    },
    isAdmin : ()=>{
        let userInfo = window.localStorage.getItem("userInfo")
        if(!userInfo){
            return false;
        }
        let userGroup = userInfo.group
        return userGroup === "admin" || userGroup === "superAdmin";
    }
}


export default userInfoUtil