//axiosInstance.js
//导入axios
import axios from 'axios'
import {ElMessage} from "element-plus";
import UserInfoUtil from "@/utils/userInfoUtil";

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const REQUEST = axios.create({
    baseURL: '/api', //请求后端数据的基本地址，自定义
    timeout: 5000 //请求超时设置，单位ms
})

// 请求拦截器
REQUEST.interceptors.request.use(config=>{
    // 获取用户信息uid发送给后端
    let userInfo = UserInfoUtil.getUserInfo()
    if(userInfo!==null){
        config.headers.uid = userInfo.uid
        console.log(userInfo.uid)
    }
    return config; // 返回这个配置对象，如果没有返回，这个请求就不会发送出去
    },
    (error)=>{
        return Promise.reject(error);
    }
)

//响应拦截
REQUEST.interceptors.response.use(response=>{
    // 进行响应拦截器的原因是:当登录控制台后,因为后台token有失效时间,所以通过响应拦截器使失效后页面回到登录页
    if (response.status === 200) {
        // 判断数据的code是否是401,是的话需要重定向到登录页面
        if(response.data.code===401){
            UserInfoUtil.clearUserInfo()
        }
        return response.data;
    } else {
        ElMessage.error("请求服务端异常，请联系管理员")
        return Promise.reject(new Error("请求服务端异常，请联系管理员"))
    }
},error=>{
    ElMessage.error("服务器连接失败，请联系管理员")
    // 将未处理的异常往外抛
    return Promise.reject(error)
});

//导出我们建立的axios实例模块，ES6 export用法
export default REQUEST