<template>
  <div class="content-card el-card"  @click="gotoArticleDetails">
    <div class="content-img">
      <img :src="article.thumb">
    </div>
    <div class="content-info">
      <div class="content-title">
        <span v-if="article.topFlag" class="badge" style="display: inline-block">置顶</span>
        {{article.title}}
      </div>
      <div class="content-text-wraper">
        <div class="content-text">
          {{ removeTags(article.text) }}
        </div>
      </div>
      <div class="content-details-info">
        <div class="release-time">
          {{ article.createTime }}
        </div>
        <div class="read-data">
          {{ article.comments }} 评论
          |
          {{ article.views }} 阅读
        </div>
      </div>
    </div>
  </div>
</template>
<script setup >
import {useRouter } from "vue-router"
const router = useRouter()
const props = defineProps({
    article: {}
})

const removeTags = (html)=>{
  html = html.toString();
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return html.replace( /(<([^>]+)>)/ig, '');
}

const gotoArticleDetails = () => {
    router.push({
        name: 'article_details',
        query: {
            cid: props.article.aid
        }
    })
}
</script>
<style scoped>
.content-card {
    //margin: 6px auto;
    display: flex;
    justify-content: space-between;
  padding: 15px;
}

/* 文章图片样式*/
.content-img{
  width: 210px;
  height: 140px;
}

@media (max-width: 1200px){
  .content-img{
    width: 185px;
    height: 125px;
  }
}

@media (max-width: 768px){
  .content-img{
    width: 120px;
    height: 80px;
  }
}
.content-img img{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  transition: opacity 0.35s;
}

.content-info{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 6px;
}

.content-text-wraper{
  flex-grow: 1;
}

.content-text {
  margin-top: 2px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
@media (max-width: 768px){
  .content-text{
    display: none;
  }
}

.content-title{
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-items: center;
}
@media (max-width: 768px){
  .content-title{
    font-size: 14px;
  }
}

.badge {
  height: 20px;
  line-height: 20px;
  background-image: -webkit-linear-gradient(0deg, #3ca5f6 0%, #a86af9 100%);
  color: #fff;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 2px;
  padding: 0 8px;
  white-space: nowrap;
  vertical-align: 2px;
}

.content-details-info {
    text-align: right !important;
    vertical-align: bottom;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    color: #666;
  margin-top: 5px;

  display: flex;
  justify-content: space-between;

}
</style>