const userAPI = {
    // 用户登录
    userLogin:{
        url:"/user/login",
    },
    // 用户注册
    userReg:{
        url:"/user/reg",
    },
    // 通过session获取用户信息
    userInfo:{
        url:"/user/info"
    },
    // 修改用户信息
    editUserInfo:{
        url:"/user/editUserInfo"
    },
    // 修改用户密码
    editUserPassword:{
        url:"/user/editUserPassword"
    },
    // 发送邮箱验证码
    sendMailVerifyCode:{
        url:"/user/sendMailVerifyCode"
    },
    // 修改用户邮箱
    editUserMail:{
        url:"/user/editUserMail"
    },
    // 退出登录
    userLogout:{
        url:"/user/logout"
    },
    // 判断用户是否是管理员
    isUserAdmin:{
        url:"/user/isAdmin"
    },
    // 判断用户是否是超管
    isUserSuperAdmin:{
        url:"/user/isSuperAdmin"
    },
    // 修改用户信息
    updateUserAvatar:{
        url:"/user/updateUserAvatar"
    },
    // 管理端通过uid获取用户信息
    AdminGetUserInfoByUid:{
        url:"/admin/user/getUserInfo/{uid}"
    },
    // 管理端更新用户信息
    AdminUpdateUserInfo:{
        url:"/admin/user/updateUserInfo"
    },
    // 管理端添加用户信息
    AdminAddUserInfo:{
        url:"/admin/user/addUserInfo"
    },
    // 管理端重置用户密码
    AdminResetUserPassword:{
        url:"/admin/user/resetUserPassword/{uid}"
    },
    AdminGetAllUserByPage:{
        url:"/admin/user/getAllUserByPage/{currentPage}/{pageSize}"
    }
}

export default userAPI;