import { createRouter, createWebHashHistory } from 'vue-router'
import HomeIndex from "@/views/index/home/HomeIndex.vue";
import REQUEST from "@/utils/request";
import UserAPI from "@/apis/UserAPI";
import {ElMessage} from "element-plus";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeIndex
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/home/HomeSearch.vue')
  },
  {
    path: '/user/index',
    name: 'user_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/user/UserIndex.vue')
  },
  {
    path: '/user/login',
    name: 'user_login',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/user/UserLogin.vue')
  },
  {
    path: '/user/reg',
    name: 'user_reg',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/user/UserReg.vue')
  },
  {
    path: '/article/index',
    name: 'article_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/article/ArticleIndex.vue')
  },
  {
    path: '/article/details',
    name: 'article_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/article/ArticleDetails.vue')
  },
  {
    path: '/question/index',
    name: 'question_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/question/QuestionIndex.vue')
  },
  {
    path: '/question/details',
    name: 'question_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/question/QuestionDetails.vue')
  },
  {
    path: '/admin/question/index',
    name: 'admin_question_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/question/QuestionIndex.vue')
  },
  {
    path: '/admin/question/write',
    name: 'admin_question_write',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/question/QuestionWrite.vue')
  },
  {
    path: '/admin/question/edit',
    name: 'admin_question_edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/question/QuestionEdit.vue')
  },
  {
    path: '/admin/home',
    name: 'admin_home',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/home/AdminHome.vue')
  },
  {
    path: '/admin/article/edit',
    name: 'admin_article_edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/article/ArticleEdit.vue')
  },
  {
    path: '/admin/article/write',
    name: 'admin_article_write',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/article/ArticleWrite.vue')
  },
  {
    path: '/admin/article/index',
    name: 'admin_article_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/article/ArticleIndex.vue')
  },
  {
    path: '/admin/user/index',
    name: 'admin_user_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/user/AdminUserIndex.vue')
  },
  {
    path: '/admin/sys/index',
    name: 'admin_sys_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/sys/AdminSysIndex.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let toPath = to.path
  let fromPath = from.path

  if(toPath.startsWith("/admin")){
    console.log("fromPath=",fromPath)
    console.log("toPath=",toPath)
    REQUEST.get(UserAPI.isUserAdmin.url).then(res=>{
      if(!res.data){
        ElMessage.error("无权限进入后台")
        if(fromPath.startsWith("/admin")){
          router.push("/");
        }else {
          // router.go(0);
        }
      }else {
        next()
      }
    }).catch(error=>{
      // router.go(0);
    })
  }else {
    next()
  }
});

export default router
